/**
 * @generated SignedSource<<4716675a8d7e18345de69dde4e18398d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CareType = "CAREER_COACHING" | "COACHING" | "HYBRID_CARE" | "THERAPEUTIC_STRUCTURED" | "THERAPEUTIC_UNSTRUCTURED";
export type PaymentStatusEnum = "FAILED" | "PENDING" | "REFUNDED" | "SUCCESS";
export type SessionStatusEnum = "ACTIVE" | "AWAITING_PAYMENT" | "CANCELLED" | "CANCELLED_LATE" | "COMPLETED" | "EMPLOYEE_NO_SHOW" | "NO_SHOW" | "PAYMENT_EXPIRED" | "PROFESSIONAL_NO_SHOW" | "SCHEDULED";
export type SessionTypeEnum = "DROP_IN" | "MANAGER_ONE_TO_ONE" | "TEST" | "THERAPY";
export type WorkshopTypeEnum = "CLASS" | "MANAGER_WORKSHOP" | "PRACTICE" | "TALK" | "WORKSHOP_ONLY";
import { FragmentRefs } from "relay-runtime";
export type useSessionCard_session$data = {
  readonly __typename: "GroupSession";
  readonly durationInMinutes: number;
  readonly endDate: string;
  readonly hosts: ReadonlyArray<{
    readonly name: string;
    readonly surname: string;
  }>;
  readonly id: string;
  readonly participants: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly startDate: string;
  readonly status: SessionStatusEnum;
  readonly workshop: {
    readonly illustration: string;
    readonly illustrationCard: string;
    readonly private: boolean;
    readonly slug: string;
    readonly title: string;
    readonly type: WorkshopTypeEnum;
  } | null | undefined;
  readonly " $fragmentType": "useSessionCard_session";
} | {
  readonly __typename: "Session";
  readonly careType: CareType | null | undefined;
  readonly checkoutUrl: string | null | undefined;
  readonly employee: {
    readonly name: string;
    readonly surname: string;
  } | null | undefined;
  readonly endDate: string;
  readonly hasPendingCheckInSurvey: boolean;
  readonly id: string;
  readonly number: number;
  readonly paymentStatus: PaymentStatusEnum | null | undefined;
  readonly professional: {
    readonly clinicalProfile: {
      readonly image: string | null | undefined;
      readonly professionalTitle: string | null | undefined;
    } | null | undefined;
    readonly name: string;
    readonly surname: string;
  } | null | undefined;
  readonly startDate: string;
  readonly status: SessionStatusEnum;
  readonly therapy: {
    readonly careType: CareType | null | undefined;
  } | null | undefined;
  readonly type: SessionTypeEnum;
  readonly " $fragmentType": "useSessionCard_session";
} | {
  // This will never be '%other', but we need some
  // value in case none of the concrete values match.
  readonly __typename: "%other";
  readonly " $fragmentType": "useSessionCard_session";
};
export type useSessionCard_session$key = {
  readonly " $data"?: useSessionCard_session$data;
  readonly " $fragmentSpreads": FragmentRefs<"useSessionCard_session">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "careType",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "surname",
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  (v7/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useSessionCard_session",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "checkoutUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasPendingCheckInSurvey",
          "storageKey": null
        },
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "professional",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ClinicalProfile",
              "kind": "LinkedField",
              "name": "clinicalProfile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "image",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "professionalTitle",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "employee",
          "plural": false,
          "selections": (v8/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Therapy",
          "kind": "LinkedField",
          "name": "therapy",
          "plural": false,
          "selections": [
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentStatus",
          "storageKey": null
        }
      ],
      "type": "Session",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "durationInMinutes",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "hosts",
          "plural": true,
          "selections": (v8/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Workshop",
          "kind": "LinkedField",
          "name": "workshop",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "private",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "illustrationCard",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "illustration",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "slug",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "participants",
          "plural": true,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "type": "GroupSession",
      "abstractKey": null
    }
  ],
  "type": "SessionResponse",
  "abstractKey": "__isSessionResponse"
};
})();

(node as any).hash = "0cec31d65c905ec4cc87065a2c458449";

export default node;
