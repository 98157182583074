import { add, isAfter, subDays } from "date-fns";
import {
  DEFAULT_CANCELLATION_NOTICE_IN_DAYS,
  SESSION_ACTIVE_THRESHOLD_IN_MINUTES,
} from "@olivahealth/constants";
import AppointmentStatus, {
  cancelledStatuses,
  plannedStatuses,
  upcomingStatuses,
} from "@olivahealth/graphql-server/src/domain/value-objects/AppointmentStatus";

export function isShortNotice(
  date: Date,
  numberOfDays: number = DEFAULT_CANCELLATION_NOTICE_IN_DAYS,
): boolean {
  const deadline = subDays(date, numberOfDays);

  return isAfter(new Date(), deadline);
}

export function isActive(date: Date | string) {
  return (
    new Date(date) <=
    add(new Date(), { minutes: SESSION_ACTIVE_THRESHOLD_IN_MINUTES })
  );
}

export function isCancelled(status?: AppointmentStatus) {
  if (!status) {
    return false;
  }

  return cancelledStatuses.includes(status);
}

export function isUpcoming(status?: AppointmentStatus) {
  if (!status) {
    return false;
  }

  return upcomingStatuses.includes(status);
}

export function isPlannedAndActive(
  status?: AppointmentStatus,
  startDate?: string,
) {
  if (!status || !startDate) {
    return false;
  }

  return plannedStatuses.includes(status) && isActive(startDate);
}
