import { EventCard } from "@olivahealth/oli-ui";

import useTranslation from "../../../hooks/useTranslation";
import useSessionCard from "./useSessionCard";
import { useSessionCard_session$key as Fragment } from "./__generated__/useSessionCard_session.graphql";

interface Props {
  session: Fragment;
}

export default function SessionCard({ session }: Props): JSX.Element | null {
  const {
    data: {
      duration,
      eventDate,
      eventType,
      eventTypeTranslation,
      isSessionActive,
      professional,
      sessionId,
      title,
      isAwaitingPayment,
      imageSource,
      buttonLabel,
      applyBackgroundMask,
      pendingProgressCheckSurvey,
      isRefunded,
    },
    effects: { handleClickButton },
  } = useSessionCard(session);
  const { t } = useTranslation("employeeOneToOnePage", {
    keyPrefix: "yourSessions",
  });

  if (session == null) {
    return null;
  }

  return (
    <EventCard
      variant="v2"
      buttonLabel={buttonLabel}
      onClickButton={handleClickButton}
      date={eventDate}
      duration={duration ? t("sessionCard.duration", { duration }) : undefined}
      id={sessionId}
      professional={professional}
      title={title}
      type={eventType}
      url={`/sessions/${sessionId}`}
      awaitingPayment={
        isAwaitingPayment ? t("sessionCard.awaitingPayment") : null
      }
      isActive={isSessionActive ? t("sessionCard.now") : undefined}
      imageSource={imageSource}
      applyBackgroundMask={applyBackgroundMask}
      eventTypeTranslation={eventTypeTranslation}
      pendingProgressCheckSurvey={
        pendingProgressCheckSurvey
          ? t("sessionCard.pendingProgressCheckSurvey")
          : null
      }
      {...(isRefunded && { refunded: t("sessionCard.refunded") })}
    />
  );
}
