import { SessionStatus } from "@prisma/postgresClient";

export default SessionStatus;

export const BillableSessionStatus = [
  SessionStatus.ACTIVE,
  SessionStatus.SCHEDULED,
  SessionStatus.COMPLETED,
  SessionStatus.CANCELLED_LATE,
  SessionStatus.EMPLOYEE_NO_SHOW,
  SessionStatus.NO_SHOW,
];

export const BillableSessionStatusWithDNAChargesEnabled = [
  SessionStatus.ACTIVE,
  SessionStatus.SCHEDULED,
  SessionStatus.COMPLETED,
  SessionStatus.NO_SHOW,
];

export const EmployeeRecordSessionStatus = [
  SessionStatus.AWAITING_PAYMENT,
  SessionStatus.SCHEDULED,
  SessionStatus.ACTIVE,
  SessionStatus.COMPLETED,
  SessionStatus.CANCELLED,
  SessionStatus.CANCELLED_LATE,
  SessionStatus.EMPLOYEE_NO_SHOW,
  SessionStatus.PROFESSIONAL_NO_SHOW,
  SessionStatus.NO_SHOW,
];

export const NoShowSessionStatus = [
  SessionStatus.EMPLOYEE_NO_SHOW,
  SessionStatus.PROFESSIONAL_NO_SHOW,
  SessionStatus.NO_SHOW,
];

export type NoShowSessionStatus = (typeof NoShowSessionStatus)[number];
